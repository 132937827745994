import {
  Component, ElementRef, OnInit, ViewChild, Renderer2, Inject, ViewContainerRef, HostListener, ChangeDetectorRef
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { SessionService, SitePageService, EventService, BillingService } from 'src/app/services/';

import { delay, fromEvent, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


/** SHARED COMPONENTS */
import { SalesExitIntentModalComponent, PaymentComponent, CheckoutChipsComponent, TimerSalesComponent, RegistrationFormComponent, MasterclassReferralInputsComponent } from 'src/app/shared';
import { environment } from 'src/environments/environment';


enum PageType {
  LANDER = 'lander',
  SALES = 'sales',
  PROMO = 'promo',
  UPSELL = 'upsell',
  SALES_CTX = 'sales-ctx',
  MASTERCLASS_REFERRAL = 'masterclass-referral'
}

@Component({
  selector: 'app-generic-urlhandler',
  templateUrl: './generic-urlhandler.component.html',
  styleUrls: ['./generic-urlhandler.component.scss'],
})
export class GenericURLHandlerComponent implements OnInit {
  embeddedScripts = [] as any;
  headerTemplate: number = 0;
  footerTemplate: number = 1;
  checkoutTemplate: number = 1;
  pageType: string = '';
  planOptions: any;
  userData: any;
  quizResponseAndProfile: any;
  quizVersionSlug: string;
  pageId: string;
  unsubscribe$ = new Subject();
  cssClasses: string;
  pageColorCss: string = 'page-color';

  /** Dynamic Components */
  containerRefRegister: any;
  containerRefPayment: any;
  dynamicComponents = {
    heroTimer: {
      show: false,
      container: 'hero-timer',
      insertComponent: (element) => {
        this.insertDynamicTimer(element, TimerSalesComponent)
      }
    },
    discountTimer: {
      show: false,
      container: 'personal-discount-timer',
      insertComponent: (element) => {
        this.insertDynamicTimer(element, TimerSalesComponent)
      }
    },
    // Adds only CheckoutChips
    // [ /post-quiz/custom-plan-v024, ]
    checkoutChips: {
      show: false,
      container: 'checkout-chips-container',
      insertComponent: (element) => {
        this.insertDynamicCheckoutChips(element, CheckoutChipsComponent)
      }
    },
    // Add CheckoutChips along with Registation Form and Payment Methods
    // [ /promo/challenge/v011, ]
    checkout: {
      show: false,
      container: 'checkout-container',
      insertComponent: (element) => {
        this.insertDynamicCheckoutChips(element, CheckoutChipsComponent, true)
      }
    },
    // Adds only Registation Form
    registration: {
      show: false,
      container: 'register-me-container',
      insertComponent: (element) => {
        this.insertDynamicRegistration(element, RegistrationFormComponent);
      }
    },
    // Adds only Payment Methods
    paymentMethods: {
      show: false,
      container: 'payment-methods-container',
      insertComponent: (element) => {
        this.insertDynamicRegistration(element, PaymentComponent);
      }
    },
    referralInputs: {
        show: false,
        container: 'referral-container',
        insertComponent: (element) => {
          this.insertDynamicReferralInputs(element, MasterclassReferralInputsComponent)
        }
      }
  }


  /** Booleans */
  externaltimer: boolean = false;
  showContent: boolean = false;
  newUser: boolean = true;
  showCheckout: boolean = false;
  showGuarantee: boolean = false;
  containsWistiaVideo: boolean = true;
  localhost: boolean = false;
  showCheckoutMasterClass: boolean = true;
  showTimer: boolean = true;
  isFairyFunnel: boolean = false;

  sitePage;

  @ViewChild('pageContent', { static: false }) pageContent!: ElementRef<HTMLElement>;
  @ViewChild("registerContainer") registerContainer: ElementRef;
  @ViewChild('appPayment') paymentContainer: ElementRef;

  constructor(
    private sitePageService: SitePageService,
    private renderer2: Renderer2,
    private elementRef: ElementRef,

    @Inject(DOCUMENT) private _document: Document,
    private titleService: Title,
    public route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private sessionService: SessionService,
    private eventService: EventService,
    private vcr: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    public billingService: BillingService
  ) { }

  ngOnInit(): void {
    // User data comes from resolver for post quiz pages
    if ('userData' in this.route.snapshot.data) {
      let data = this.route.snapshot.data['userData'];

      // Route checked with resolver
      if (data) {
        this.quizResponseAndProfile = data;
        if (data.profile)
          this.newUser = false;
      } else if (localStorage.getItem('wid_token'))
        this.newUser = false;
    }


    var loc = new URL(window.location.href);
    const pathname = loc.pathname;
    const searchParams = loc.searchParams;

    if (location.origin.indexOf("localhost") != -1)
      this.localhost = true;

    if (pathname) {
      this.sitePageService.loadSitePage(pathname).subscribe((sitePage) => {
        //  this condition should never happen but just in case
        if (sitePage == undefined) {
          window.location.href = environment.wwwUrl;
          return;
        }

        this.sitePage = sitePage;
        this.pageType = sitePage.type;
        if (sitePage.billing)
          this.planOptions = sitePage.billing;
        if (sitePage.title) {
          this.titleService.setTitle(sitePage.title);
        }
        this.pageId = sitePage.pageId;
        this.quizVersionSlug = sitePage.quizVersionSlug;
        this.sessionService.setQuizVersionSlug(sitePage.quizVersionSlug);

        const obj = sitePage.content;

        this.headerTemplate = obj.header;
        this.footerTemplate = obj.footer;
        this.showGuarantee = obj.showGuarantee;
        this.cssClasses = obj.cssClasses;
        this.showTimer = obj.hasOwnProperty("showTimer") ? obj.showTimer : this.showTimer;
        this.isFairyFunnel = obj.hasOwnProperty("isFairyFunnel") ? obj.isFairyFunnel : false;
        // this.showCheckoutMasterClass = obj.hasOwnProperty("showCheckout") ? obj.showCheckout : this.showCheckoutMasterClass;

        if (this.isFairyFunnel) {
          this.pageColorCss = obj.pageColorCssClasses;
        }

        if (sitePage && sitePage.type == PageType.LANDER) {
          if (obj.hasOwnProperty('content'))
            this.renderLanderPage(obj.content);
          else if (obj.hasOwnProperty('contentLink'))
            this.renderLanderPageFromLink(obj.contentLink);
        }
        else if (sitePage && sitePage.type == PageType.MASTERCLASS_REFERRAL) {
          if (obj.hasOwnProperty('contentLink'))
            this.renderLanderPageFromLink(obj.contentLink);
        }
        else// if (layout && layout.type == PageType.SALES)
        {
          if (obj.hasOwnProperty('content'))
            this.renderSalesPage(obj);
          else if (obj.hasOwnProperty('contentLink'))
            this.renderSalesPageFromLink(obj);
        }

        if (this.pageType != PageType.LANDER && this.pageType != PageType.UPSELL)
          this.showCheckout = true;

        if (sitePage.type == PageType.SALES && this.planOptions.exitIntent) {
          this.startExitModalEventHandler();
        }
        if (sitePage.type == PageType.SALES)
          this.sendEvents('payment page viewed');

        if ((sitePage.content.enableCheckoutOnclick || sitePage.subType == PageType.SALES_CTX) && !('paymentType' in this.route.snapshot.queryParams)) {
          this.showCheckout = false;
          this.enableButtonClick();
        }

        // Final overriding checkout flag check if exists
        this.showCheckout = obj.hasOwnProperty("showCheckout") ? obj.showCheckout : this.showCheckout;
      });
    }
  }

  enableButtonClick() {
    timer(1500).subscribe(() => {
      document.querySelector('.contextual-sales-get-started-btn')
        .addEventListener("click", () => {
          const element = document.querySelector<HTMLElement>(".contextual-sales-context");
          if (element)
            element.style.display = 'block';
          this.showCheckout = true;
        });
    })
  }

  /**
   * Change take the quiz href link for lander pages to internal angular route
   */
  reRouteHrefLinks() {
    if (this.pageType != PageType.LANDER)
      return;

    const el = this.pageContent.nativeElement;
    if (el) {
      // this code only gets run in the browser, as other envs have (akaik) no nativeelement
      const links = Array.from(el.querySelectorAll("a"))
        .filter(l => l.hasAttribute('weshape-router-link'))
        .forEach(l => {
          l.addEventListener('click', this.reroute(this));
          l.style.cursor = "pointer";
        });
    }
  }

  reroute(me) {
    return function reroute(ev) {
      ev.preventDefault();
      const url = this.getAttribute("href");
      if (url)
        me.router.navigate([url]);
      else
        me.router.navigate(['/quiz']);
    };
  }

  ngOnDestroy(): void {
    // Remove Scripts that were manually added in
    this.sitePageService.removeAllScripts();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  renderLanderPageFromLink(link) {
    this.includeHTMLFromLink(link, PageType.LANDER);
  }

  renderLanderPage(encodedContent: any) {
    const htmlContent: string = atob(encodedContent);
    this.pageContent.nativeElement.innerHTML = htmlContent;
    this.checkWistiaContent(htmlContent);
    this.checkTimerCountdownContent(htmlContent);

  }

  renderSalesPageFromLink(obj) {
    this.checkoutTemplate = obj.checkout;
    this.includeHTMLFromLink(obj.contentLink, this.pageType);
  }

  renderSalesPage(obj) {
    this.checkoutTemplate = obj.checkout;
    const htmlContent: string = atob(obj.content);
    this.pageContent.nativeElement.innerHTML = htmlContent;
    this.checkWistiaContent(htmlContent);
    this.checkTimerCountdownContent(htmlContent);
  }

  checkWistiaContent(content) {
    /* Generate scripts for this video to load */
    var wistiaPattern = "wistia_embed wistia_async_";
    this.containsWistiaVideo = (content.search(wistiaPattern) != -1);
    if (this.containsWistiaVideo)
      this.sitePageService.createScript('https://fast.wistia.com/assets/external/E-v1.js');
  }

  checkTimerCountdownContent(content) {
    /* Generate scripts for this video to load */
    let timerElement = "countdown-timer";
    let containsCountdownTimer = (content.search(timerElement) != -1);

    if (!containsCountdownTimer) return;

    let cssLink = '/dynamic-assets/styles/countdown-timer.css';
    let scriptLink = '/dynamic-assets/js/countdown-timer.js';

    if (this.localhost) {
      cssLink = "https://development.weshape.com" + cssLink;
      scriptLink = "https://development.weshape.com" + scriptLink;
    }

    // Set various information required for external countdown timer
    // Fetching timer options from billing_promo column based on timer design
    let timerSetting = {
      remainingTime: this.planOptions?.ends,
      timerElement: timerElement
    }
    let settings = Object.assign(timerSetting, this.planOptions?.timerSettings);

    // Store into global variable to be read by external script
    window.wsTimerSettings = settings;
    this.externaltimer = true;

    // Insert external timer script and stylesheet
    this.sitePageService.createScriptCSS(cssLink);
    this.sitePageService.createScript(scriptLink);
  }

  /**
   * Check for any redirect buttons that requires data about user
   * button-redirect MUST be of type anchor
   */
  checkAndUpdateRedirectButton(content) {
    let containerEl = this.elementRef.nativeElement.querySelectorAll(".button-redirect");
    if(containerEl.length > 0) {
      containerEl.forEach(element => {
        let url = element.getAttribute('data-url');
        let redirectType = element.getAttribute('data-redirect-type');

        // Redirecting to challenge referral page will require the user's first name and email as a query param
        if(redirectType === 'challenge-referral') {
          if(this.quizResponseAndProfile) {
            let firstName = this.quizResponseAndProfile.response?.names?.first_name;
            element.href = `${url}?fn=${firstName}&email=${encodeURIComponent(this.quizResponseAndProfile.email)}`;
          } else {
            // Cannot redirect user to referral page as required information is missing
            element.href = "/"
          }
        } else if (url) {
          element.href = url;
        } else {
          // Default to stay on page if no url is defined
          element.href = "#";
        }
      });
    }
  }

  planSelected($event) {
    this.billingService.planSelected = $event;
    if(this.paymentContainer)
      this.scrollIntoView(this.paymentContainer);
    if (this.registerContainer)
      this.scrollIntoView(this.registerContainer)
  }

  /**
  * Check for dynamic component containers in the static pages
  * Insert Component if found
  */
  checkAndInsertDynamicContent(content) {
    Object.keys(this.dynamicComponents).forEach(component => {
      let item = this.dynamicComponents[component];
      let el = item.container;
      item.show = (content.search(el) != -1);

      // Insert Required Components
      if (item.show) {
        item.insertComponent(el);
      }
    })
  }

  /**
   * Creates CheckoutChipsComponent dynamically to insert into containers
   */
  insertDynamicCheckoutChips(container, component, showPayment = false) {
    if (!container && !component) return;

    // Get all checkout containers
    let containerEl = this.elementRef.nativeElement.querySelectorAll(`.${container}`);
    let paymentInitialized = false;

    containerEl.forEach(element => {
      // Create checkout component to dynamically insert into views
      const containerRef = this.vcr.createComponent<typeof component>(component);
      let legalEl = this.elementRef.nativeElement.querySelectorAll(`.legal-terms`);

      // Set Inputs
      containerRef.setInput('planOptions', this.planOptions);
      containerRef.setInput('pageType', this.pageType);
      containerRef.setInput('cssClasses', this.cssClasses);

      let selectedIndex = this.planOptions.productItem.findIndex(element => {
        return element.active === 'active'
      });

      if (selectedIndex > -1) {
        containerRef.setInput('selectedIndex', selectedIndex);
        legalEl.forEach(container => {
          container.innerHTML = this.planOptions.productItem[selectedIndex].legal
        });
      }

      // Set Output Listener
      containerRef.instance.planSelected
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(val => {
          this.billingService.planSelected = val;

          if (showPayment) {
            if (this.newUser && this.billingService.planSelected)
              this.insertDynamicRegistration(container, RegistrationFormComponent);
            else {
              if (!paymentInitialized) {
                paymentInitialized = true;
                this.insertDynamicPayment(container, PaymentComponent)
              }
            }
          }
          // Get legal terms and update appropriately on page
          legalEl.forEach(container => {
            container.innerHTML = val.selectedPlan.legal
          });
        });
      this.renderer2.appendChild(element, containerRef.location.nativeElement)
    })
  }

   /**
   * Creates RegistrationFormComponent dynamically to insert into containers
   */
   insertDynamicRegistration(container, component) {
      if (!container && !component) return;

      // Hides default checkout component from template
      this.showCheckout = false;

      // Get all checkout containers
      let containerEl = this.elementRef.nativeElement.querySelectorAll(`.${container}`);

      containerEl.forEach(element => {
        // Create checkout component to dynamically insert into views
        if (!this.containerRefRegister)
          this.containerRefRegister = this.vcr.createComponent<typeof component>(component);

        // Set Inputs
        this.containerRefRegister.setInput('isFairyFunnel', this.isFairyFunnel);
        this.containerRefRegister.setInput('cssClasses', this.cssClasses);

        // Set Output Listener
        this.containerRefRegister.instance.updateUser
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(val => {
            this.billingService.showOptions = true;
            this.insertDynamicPayment(container, PaymentComponent)
          });
        this.renderer2.appendChild(element, this.containerRefRegister.location.nativeElement)
      })

   }
 /**
   * Creates PaymentComponent dynamically to insert into containers
   */
  insertDynamicPayment(container, component) {
    if (!container && !component) return;

    // Hides default checkout component from template
    this.showCheckout = false;

    // Get all checkout containers
    let containerEl = this.elementRef.nativeElement.querySelectorAll(`.${container}`);

    containerEl.forEach(element => {
      // Create checkout component to dynamically insert into views
      if (!this.containerRefPayment)
        this.containerRefPayment = this.vcr.createComponent<typeof component>(component);

      // If pageType is promo but still show regular check instead of promo checkout chips. URL: */promo/wow-offer
      let activeRegularCheckoutchipForPromo = element.getAttribute('data-regular-checkout');

      if(activeRegularCheckoutchipForPromo) {
        this.planOptions['active'] = false;
      }

      // Set Inputs
      this.containerRefPayment.setInput('showCheckoutMasterClass', this.showCheckoutMasterClass);
      this.containerRefPayment.setInput('planOptions', this.planOptions);
      this.containerRefPayment.setInput('pageType', this.pageType);
      this.containerRefPayment.setInput('newUser', this.newUser);
      this.containerRefPayment.setInput('pageId', this.pageId);
      this.containerRefPayment.setInput('cssClasses', this.cssClasses);
      this.containerRefPayment.setInput('isFairyFunnel', this.isFairyFunnel);

      this.renderer2.appendChild(element, this.containerRefPayment.location.nativeElement)
    })
  }

  /**
   * Creates HeroTimerComponent dynamically to insert into containers
   */
  insertDynamicTimer(container, component) {
    if (!container && !component) return;

    let containerEl = this.elementRef.nativeElement.querySelectorAll(`.${container}`);
    containerEl.forEach(element => {
      // Create component to dynamically insert into views
      const containerRef = this.vcr.createComponent<typeof component>(component);
      let version = element.getAttribute('data-timer-version');
      let tagline = element.getAttribute('data-timer-tagline');
      let buttonText = element.getAttribute('data-timer-button-text');
      let scrollToEl = element.getAttribute('data-timer-scroll-to');


      // Set Inputs
      containerRef.setInput('tagline', tagline);
      containerRef.setInput('timerInSeconds', this.planOptions.timerSettings.secondsToEnd);
      containerRef.setInput('buttonText', buttonText);
      containerRef.setInput('version', version);

      // Set Output Listener
      if (containerRef.instance.buttonClicked) {
        containerRef.instance.buttonClicked
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(val => {
            this.elementRef.nativeElement.querySelector(scrollToEl).scrollIntoView({ behavior: 'smooth' });
          });
      }
      this.renderer2.appendChild(element, containerRef.location.nativeElement)
    })
  }

  insertDynamicReferralInputs(container, component) {
    if (!container && !component) return;

    let containerEl = this.elementRef.nativeElement.querySelectorAll(`.${container}`);
    containerEl.forEach(element => {
      // Create component to dynamically insert into views
      const containerRef = this.vcr.createComponent<typeof component>(component);
      let numStartReferral = element.getAttribute('data-num-start-referrals') || 2;
      let numMaxReferral = element.getAttribute('data-num-max-referrals') || 10;
      let numAdditionalInputs = element.getAttribute('data-num-additional-inputs') || 2;

      // Set Inputs
      containerRef.setInput('numStartReferral', numStartReferral);
      containerRef.setInput('numMaxReferral', numMaxReferral);
      containerRef.setInput('numAdditionalInputs', numAdditionalInputs);
      containerRef.setInput('userData', this.quizResponseAndProfile);
      containerRef.setInput('pageData', this.sitePage);

      this.renderer2.appendChild(element, containerRef.location.nativeElement)
    })
  }

  insertDynamicScripts(arr) {
    arr.forEach(link => {
      let isDynamicAssetsLink = link.includes("dynamic-assets");
      let scriptLink =
        (this.localhost && isDynamicAssetsLink)
          ? "https://development.weshape.com" + link
          : link;

      this.sitePageService.createScript(scriptLink, true);
    })
  }

  includeHTMLFromLink(link, pageType) {
    var xhttp;
    xhttp = new XMLHttpRequest();
    var that = this;
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4) {
        if (this.status == 200) {
          if (pageType == PageType.SALES)
            that.pageContent.nativeElement.innerHTML = that.processSalesPageContent(this.responseText);

          else if (pageType == PageType.PROMO) {
            that.pageContent.nativeElement.innerHTML = that.processPromoContent(this.responseText);
          }
          else
            that.pageContent.nativeElement.innerHTML = that.replaceAssetsPath(this.responseText);

          that.checkAndInsertDynamicContent(that.pageContent.nativeElement.innerHTML);
          that.checkAndUpdateRedirectButton(that.pageContent.nativeElement.innerHTML);


          // Render Any External Scripts if found
          if (that.sitePage?.content?.externalScripts) {
            that.insertDynamicScripts(that.sitePage?.content?.externalScripts);
          }
        }
        if (this.status == 404) {
          that.pageContent.nativeElement.innerHTML = "Page not found.";
        }
      }
    }
    if (this.localhost)
      link = "https://development.weshape.com" + link;
    xhttp.open("GET", link, true);
    xhttp.send();
  }

  processPromoContent(content) {
    content = this.replaceAssetsPath(content);
    this.checkWistiaContent(content);
    this.checkTimerCountdownContent(content);

    return content;
  }

  processSalesPageContent(content) {
    content = this.replaceWorkoutDays(content);
    content = this.replaceAssetsPath(content);
    this.checkWistiaContent(content);
    this.checkTimerCountdownContent(content);

    if (this.planOptions.replaceContextualText)
      content = this.replaceContextualTextItems(content)
    if (this.planOptions.replaceItems) {
      var items: string[] = this.planOptions.replaceItems.split(",");
      items.forEach(element => {
        var value = element;
        if (element.indexOf('quizResponse.') != -1) {
          const arr = element.split('.');
          let quiz = JSON.parse(window.localStorage.getItem(this.sessionService.quizItemKey))
          if (quiz)
            value = quiz[arr[1]]?.toString();
          element = arr[1].toLowerCase();
        }else if (element.indexOf('response.') != -1 || element.indexOf('profile.') != -1) {
          const arr = element.split('.');
          value = this.quizResponseAndProfile[arr[0]][arr[1]];
          element = arr[1].toLowerCase();
        }
        else {
          value = this.planOptions[element];
        }

        if (value) {
          const regex = new RegExp('\\[' + element + '\\]', 'g');
          content = content.replace(regex, value);
        }
      });
    }
    return content;
  }

  replaceContextualTextItems(content) {
    var replaceItems;
    Object.keys(this.planOptions.replaceContextualText).forEach((key) => {
      if (key == this.quizResponseAndProfile?.response.goal) {
        replaceItems = this.planOptions.replaceContextualText[key];
        if (this.planOptions.replaceContextualText[key].challenge) {
          Object.keys(this.planOptions.replaceContextualText[key].challenge).forEach(element => {
            if (element == this.quizResponseAndProfile?.response.challenge) {
              replaceItems = this.planOptions.replaceContextualText[key].challenge[element];
            }
          });
        }
      }
    });

    Object.keys(replaceItems).forEach(element => {
      const regex = new RegExp('\\[' + element + '\\]', 'g');
      content = content.replace(regex, replaceItems[element]);
    });
    return content;
  }

  replaceWorkoutDays(content) {
    let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    //Only in Sales pagse: By default it loads grey icon, only for selected days we will be replacing to blue-icon
    if (this.quizResponseAndProfile?.response?.workout_days) {
      this.quizResponseAndProfile.response.workout_days.forEach(day => {
        if (content.indexOf(day) != -1) {
          content = content.replace(day, 'blue-icon');
        }
      })
    }
    days.forEach(day => {
      if (content.indexOf(day) != -1) {
        content = content.replace(day, 'grey-icon');
      }
    })
    return content;
  }

  replaceAssetsPath(content) {
    if (this.localhost) {
      const regex = /\/dynamic-assets\//g;
      content = content.replace(regex, 'https://development.weshape.com/dynamic-assets/');
    }
    return content;
  }

  startExitModalEventHandler() {
    // Add Event Handler for mouse leaving the document, Pop exit intent
    const mouseleave$ = fromEvent(this._document.documentElement, 'mouseleave');
    mouseleave$.pipe(delay(500), takeUntil(this.unsubscribe$)).subscribe((event: MouseEvent) => {
      // Only trigger when the user moves their mouse to the top of the viewport
      if (event.clientY < 0) {
        // Disable mouse leave event handler
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
        this.renderExitModals('firstView', this.quizResponseAndProfile.profile.email, this.planOptions.exitIntent);
      }
    });
  }

  renderExitModals(type = 'firstView', email, exitIntent) {
    let dialogRef = this.dialog.open(SalesExitIntentModalComponent, {
      data: {
        type,
        email,
      },
      panelClass: 'exit-modal-container',
    });

    dialogRef.afterClosed().subscribe(result => {
      // Result === undefined when user clicks on the background overlay
      if (result === 'Yes') {
        this.router.navigate([exitIntent]);
      }
      else if ((result === 'No' || result === undefined) && type === 'firstView') {
        // Render 2nd modal after 250 ms
        timer(250).subscribe(() => {
          this.renderExitModals('secondView', email, exitIntent);
        })
      }
      else if ((result === 'Close' || result === undefined) && type === 'secondView') {
        // console.log("Final Close To Do Something");
      }
    });
  }

  contentLoaded(event) {
    // Content added into this element through innerHTML
    if (event.addedNodes.length) {
      timer(500).subscribe(() => {
        this.showContent = true;
        this.reRouteHrefLinks();
      })
    }
  }

  sendEvents(event, eventProperty = {}) {
    this.eventService.sendEvent({
      event: event,
      event_properties: eventProperty
    });
  }

  scrollIntoView(container) {
    container.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  /** HOST LISTENERS */
  /**
   * Master Class Button Listener to Display Checkout
   * @param ev Event Target
   */
  @HostListener('document:click', ['$event'])
  clickOutside(ev) {
    if((ev.target.className).includes("master-class-navigation-yes")) {
      this.showCheckoutMasterClass = true;
      this.showCheckout = true;
      this.cdr.detectChanges();

    }
  }
}
